.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// custom slider UI
.slider-custom {
  margin-bottom: 8px;
  .rc-slider-rail {
    background-color: #a09f9f;
    height: 6px;
  }
  .rc-slider-track {
    background-color: #00578F;
    height: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rc-slider-handle {
    background-color: #00578F;
    border-color: #00578F;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    margin-top: -13px;
    &:hover, &:active {
      border-color: #00578F;
    }
    &[aria-valuenow="780"] {
      left: calc(100% - 30px) !important;
    }
  }
}

