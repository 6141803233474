.zipcode-page {

  .zipcode-input {
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;

    .input {
      text-align: center;
      height: 50px;
      font-size: larger;;
    }
  }

  .zipcode-btn {
    margin-top: 25px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    .btn {
      background-color: #00578F;

      .continue {
        float: left;
        width: 85%;
        text-align: center;

        .text {
          margin-left: 15%;
        }
      }

      .tick-div {
        text-align: right;
      }
    }

    .tick {
      margin-left: 15px;
    }
  }

  .terms-privacy {
    padding-top: 115px;
    a {
      color: #00578F;
    }
  }

  @media (min-height: 701px) and (min-width: 401px){
    .terms-privacy {
      padding-top: 25px;
    }
  }

  @media (max-width: 767px) {
    .zipcode-text {
      font-size: 25px;
      margin-top: 0;
    }
  }
}


.blue-text {
  color: #0527a3;
}

@media (min-width: 768px) and (max-width: 1099px) {
  .blue-text {
    margin-top: 50px;
  }
}
