.success-page {
  text-align: center;

  .success-text {
    margin-top: 25px;
    font-size: 35px;
    color: #808080;
  }

  .close-btn {
    margin-top: 25px;
  }
}
