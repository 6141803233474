.property-type-page {
  text-align: center;

  .text-row{
    margin-top: 10px;
    font-size: 30px;
  }

  @media (min-width: 768px) {
    .question-title {
      margin-bottom: 3rem!important;
    }

    .info-hover {
      max-width: 360px;
      margin: 0 auto;
      padding: 30px 0 0;
      text-align: center;
      border-radius: .25rem;
      box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
      border-color: rgba(0,0,0,.1);
      padding: 20px 0px;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.4);
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) and (max-width: 991.98px) {
    .service-call {
      display: inline-block;
      span {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 767.98px) {
    .title {
      font-weight: 700;
      padding-top: 15px;
    }

    .service-call {
      align-items: center;
      margin-top: 10px !important;
  
      h4 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    
    .info-hover {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
      border: 1px solid transparent;
      border-radius: .25rem;
      box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
      border-color: rgba(0,0,0,.1);
      padding: 5px 10px;
      transition: transform 0.5s;
      -webkit-transition: -webkit-transform 0.5s;

      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        cursor: pointer;
      }

      h4 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 10px;
      }

      .property-icon {
        width: 50px;
      }
    }
  }
}
