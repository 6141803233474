
$color-yellow:  #ff5500;
$color-blue: #365c9a;
$color-light-blue: #3e98c7;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
}

.next-btn {
  border-radius: 4px;
  background-color: $color-blue;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 20px;
  padding: 5px 10px;
  width: 250px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.next-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.next-btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.next-btn:hover span {
  padding-right: 25px;
}

.next-btn:hover span:after {
  opacity: 1;
  right: 0;
}

.mainpage-title, .zipcode-text {
  font-size: 35px;
  text-align: center;
  margin-top: 25px;
  color: $color-blue;
  font-family: 'Roboto', sans-serif;
}

.title {
  font-weight: 700;
  padding-top: 30px;
}

h2, .h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.description {
  color: #9A9A9A;
  font-weight: 300;
}

h4, .h4 {
  font-size: 24px;
  line-height: 1.45em;
  margin-top: 20px;
  margin-bottom: 10px;
}

h3, .h3 {
  font-size: 32px;
  line-height: 1.45em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.color-yellow {
  color: #ff5500 !important;
}

.color-blue {
  color: #365c9a !important;
}

.color-light-blue {
  color: #3e98c7 !important;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 0.7142em;
  line-height: 12px;
  background-color: transparent;
  border: 1px solid;
  margin-bottom: 5px;
  color: #FFFFFF;
  border-radius: 0.875rem;
  border-color: $color-light-blue;
  background-color: $color-light-blue;
  color: #FFFFFF;
}

@media only screen and (max-width: 767.98px) {
  h1 { font-size: 30px !important; }
  h2 { font-size: 25px !important; }
  h3 { font-size: 20px !important; }
  h4 { font-size: 18px !important; }
  h5 { font-size: 16px !important; }
  h6 { font-size: 14px !important; }
}

.property-icon {
  width: 80px;
}