.email-page {
  text-align: center;
  
  .email-input {
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;

    .input {
      text-align: center;
      height: 50px;
      font-size: larger;;
    }
  }

  .email-btn {
    margin-top: 25px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    .btn {
      background-color: #00578F;

      .continue {
        float: left;
        width: 85%;
        text-align: center;

        .text {
          margin-left: 15%;
        }
      }

      .tick-div {
        text-align: right;
      }
    }

    .tick {
      margin-left: 15px;
    }
  }

  @media (max-width: 767px) {
    .email-text {
      font-size: 25px;
      margin-top: 0;
    }
  }
}
