.additional-cash-page {
  text-align: center;

  .text-row {
    margin-top: 10px;
    font-size: 30px;
  }

  .range-row {
    margin-top: 5%;

    .text {
      font-size: 20px;
      color: #365c9a;
    }
  }

  .range-item {
    margin-top: 5%;

    .min {
      text-align: left;
      color: #808080;
    }

    .max {
      text-align: right;
      color: #808080;
    }
  }

  .btn-row {
    margin-top: 5%;

    .btn {
      background-color: #00578F;
    }
  }

  .goback-btn {
    margin-top: 20px;
    color: #003AF7;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}
