.refinance-type-page {
  text-align: center;

  .text-row{
    margin-top: 10px;
    font-size: 30px;
  }

  .take-cash {
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    .type-1 {
      border-radius: .25rem;
      box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
      padding: 20px 10px;
      .title {
        color: #FB1D66;
        font-size: 25px;
      }

      .good-for {
        font-size: 15px;
        color: #808080;
      }

      .item-text {
        font-size: 20px;
        color: #808080;
      }

      &:hover {
        cursor: pointer;

        background-color: #FB1D66;
        color: white;

        .title {
          color:white;
          font-size: 25px;
        }

        .good-for {
          font-size: 15px;
          color: white;
        }

        .item-text {
          font-size: 20px;
          color: white;
        }
      }
    }
  }

  .lower-payment {
    margin-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 125px;
    .type-2 {
      border-radius: .25rem;
      box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
      padding: 20px 10px;

      .title {
        color: #007EBF;
        font-size: 25px;
      }

      .good-for {
        font-size: 15px;
        color: #808080;
      }

      .item-text {
        font-size: 20px;
        color: #808080;
      }

      &:hover {
        cursor: pointer;

        background-color: #007EBF;
        color: white;

        .title {
          color:white;
          font-size: 25px;
        }

        .good-for {
          font-size: 15px;
          color: white;
        }

        .item-text {
          font-size: 20px;
          color: white;
        }
      }
    }
  }

  .shorten-loan {
    margin-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    .type-3 {
      border-radius: .25rem;
      box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
      padding: 20px 10px;

      .title {
        color: #FFA534;
        font-size: 25px;
      }

      .good-for {
        font-size: 15px;
        color: #808080;
      }

      .item-text {
        font-size: 20px;
        color: #808080;
      }

      &:hover {
        cursor: pointer;

        background-color: #FFA534;
        color: white;

        .title {
          color:white;
          font-size: 25px;
        }

        .good-for {
          font-size: 15px;
          color: white;
        }

        .item-text {
          font-size: 20px;
          color: white;
        }
      }
    }
  }

}
