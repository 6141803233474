.mortgage-result-page {
  text-align: center;

  .input-form {
    margin-top: 5%;

    .btn {
      background-color: #00578F;
    }

  }
}
