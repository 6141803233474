.sorry-page {
  text-align: center;
  .icon-row {
    margin-top: 100px;

    .icon {
      height: 150px;
      width: auto;
    }
  }

  .sorry-text {
    margin-top: 25px;
    font-size: 35px;
    color: #808080;
    padding-left: 30px;
    padding-right: 30px;
  }

  .close-btn {
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
