.main-view {
  position: relative;
  max-width: 100% !important;
  text-align: center;

  @media (min-width: 1700px) {
    .target-rate-board {
      position: absolute;
      width: 320px;
      top: 20%;
      right: 15%;
      padding: 5px 0;
      border-radius: 20px;
      border-left: 1px solid #8a8a8a;
      border-bottom: 1px solid #aaaaaa;
      box-shadow: -10px 10px 10px 0 rgba(0,0,0,.1);
    }
    h6 {
      font-size: 1.5rem !important;
    }
  }

  @media (max-width: 1699.98px) and (min-width: 1400px) {
    .target-rate-board {
      position: absolute;
      width: 240px;
      top: 20%;
      right: 15%;
      padding: 5px 0;
      border-radius: 20px;
      border-left: 1px solid #8a8a8a;
      border-bottom: 1px solid #aaaaaa;
      box-shadow: -10px 10px 10px 0 rgba(0,0,0,.1);
    }
    h6 {
      font-size: 1.3rem !important;
    }
  } 

  @media (max-width: 1399.98px) and (min-width: 992px) {
    .target-rate-board {
      position: absolute;
      width: 240px;
      top: 20%;
      right: 5%;
      padding: 5px 0;
      border-radius: 20px;
      border-left: 1px solid #8a8a8a;
      border-bottom: 1px solid #aaaaaa;
      box-shadow: -10px 10px 10px 0 rgba(0,0,0,.1);
    }
    h6 {
      font-size: 1.3rem !important;
    }
  } 

  @media (max-width: 991.98px) {
    .target-rate-board {
      position: static;
      width: auto;
      padding: 5px 0;
      margin: 0 15px !important;
      border-radius: 20px;
      border-left: 1px solid #8a8a8a;
      border-bottom: 1px solid #aaaaaa;
      box-shadow: -10px 10px 10px 0 rgba(0,0,0,.1);
    }
    h6 {
      font-size: 1rem !important;
    }
    .company-logo-container {
      padding: 20px 0;
      .company-logo {
        width: 80px;
      }
    }
  }

  @media (min-width: 992px) {
    .company-logo-container {
      padding: 60px 0;
      .company-logo {
        width: 120px;
      }
    }
  }

  @media (max-width: 767.98px) {
    padding: 40px 0;
    height: 100vh;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;

  }

  .CircularProgressbar {
    width: 140px !important;
  }
}
