.loan-options-page {
  text-align: center;

  .text-row {
    margin-top: 3%;
    font-size: 25px;
    color: #808080;
  }

  .option-1 {
    margin-top: 3%;
    border: solid #808080 1px;
    padding-bottom: 15px;
    .col {
      text-align: left;

      .text {
        color: #283B8D;
        font-size: 25px;
      }

      .title {
        text-align: center;
      }

      .data {
        text-align: center;
        padding-bottom: 100px;
      }

      .text-data {
        color: #283B8D;
      }
    }
  }

  .option-2 {
    margin-top: 5%;
    border: solid #808080 1px;
    padding-bottom: 15px;
    .col {
      text-align: left;

      .text {
        color: #283B8D;
        font-size: 25px;
      }

      .title {
        text-align: center;
      }

      .data {
        text-align: center;
        padding-bottom: 100px;
      }

      .text-data {
        color: #283B8D;
      }
    }
  }

  .option-3 {
    margin-top: 5%;
    border: solid #808080 1px;
    padding-bottom: 15px;
    .col {
      text-align: left;

      .text {
        color: #283B8D;
        font-size: 25px;
      }

      .title {
        text-align: center;
      }

      .data {
        text-align: center;
        padding-bottom: 100px;
      }

      .text-data {
        color: #283B8D;
      }
    }
  }

  .submit {
    .text-note {
      color: #808080;
      font-size: 13px;
    }

    .btn {
      background-color: #283B8D;
      margin-top: 5%;
    }
  }
}
